import {
    SHOW_AGE_EXTRA,
    SHOW_AGE_TOGGLE
} from "../actions/types";

const initialState = {
    showAge: false,
    showDescription: true,
    showAgeExtra: false
};

function homeReducer(state = initialState, action) {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case SHOW_AGE_TOGGLE:
            return Object.assign({}, state, {
                showAge: !state.showAge,
                showDescription: !state.showDescription
            });
        case SHOW_AGE_EXTRA:
            return Object.assign({}, state, {
                showAge: true,
                showDescription: false,
                showAgeExtra: true
            });
        default:
            return state;
    }
}

export default homeReducer;