import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ReadMoreInner from "../components/readMoreInner";
import Header from "../components/header";

function NoMatch (){

    return (
        <Container>
            <Header />
            <Row className='pt-5 justify-content-center'>
                <Col xs={10} className='text-center'>
                    <h1>Sivua ei löytynyt <span className='error-text'>404</span></h1>
                </Col>
            </Row>
            <Row className='pt-5 pb-3 justify-content-center'>
                <Col xs={12} md={10} className='text-center small color-white'>
                    <ReadMoreInner />
                </Col>
            </Row>
        </Container>
    )
}

export default NoMatch;