import React from "react";
import {
  useParams
} from "react-router-dom";
import {useSelector} from "react-redux";
import NoMatch from "./noMatch";
import ProductDetails from "./productDetails";

function ProductCard (){
    const {productData, pathHistory} = useSelector(state => state.globalReducer);
    let { id } = useParams();
    let product = null;
    let productDetails = <NoMatch />

    if (productData){
        product = productData.filter(p => { return p.id === id })[0]
    }

    if(product){
        productDetails = <ProductDetails product={product} pathHistory={pathHistory} />
    }

    return (
        <div>
            {productDetails}
        </div>
    )
}

export default ProductCard;