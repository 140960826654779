import React, {useEffect} from "react";
import {
    Route,
    Routes, useLocation,
} from "react-router-dom";
import Home from "./routes/home";
import Base from "./routes/base";
import NoMatch from "./routes/noMatch";
import Selection from "./routes/selection";
import {useDispatch, useSelector} from "react-redux";
import productDataJson from './products/products-15-12-2022.json';
import {importProductData} from "./actions";
import MultipleProducts from "./routes/multipleProducts";
import ProductCard from "./routes/productCard";

function App() {
    const {productData} = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    const location = useLocation();

    const queryParameters = new URLSearchParams(window.location.search)
    const queryStore = queryParameters.get("store")

    useEffect(() => {
        const titles = {
            '/': 'Nicotinell valinta - Etusivu',
            '/amount': 'Nicotinell valinta - Määrä',
            '/selection': 'Nicotinell valinta - Tuotevalinta',
        }
        if(titles[location.pathname]){
            document.title = titles[location.pathname]
        }
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'page_view',
                page_path: location.pathname + location.search + location.hash,
                page_search: location.search,
                page_hash: location.hash,
            });
        }
    }, [location]);

    useEffect(() => {
        let initialProductData = productDataJson
        if (productData === null) {

            if (queryStore){
                initialProductData = initialProductData.filter(p => {
                    if(queryStore === 'sok'){
                        return p.sok !== '';
                    }else if(queryStore === 'kesko') {
                        return p.kesko !== '';
                    }
                    else{
                        return p
                    }
                });
            }else{
                initialProductData = initialProductData.filter(p => {
                    return p.sok !== '';
                });
            }

            let payload = {
                'productData': initialProductData,
                'amountFilteredProductData': initialProductData,
                'store': queryStore
            }
            dispatch(importProductData(payload))
        }
    }, [productData, dispatch, queryStore]);


    let backgroundClassName = 'backgroundContainer base'


  return (
    <div className={backgroundClassName}>
         <Routes>
             <Route exact path='/' element={<Home />}></Route>
             <Route exact path='/amount' element={<Base />}></Route>
             <Route exact path='/selection' element={<Selection />}></Route>
             <Route
                path="selection/:format/:flavour"
                element={<MultipleProducts />}
             />
             <Route
                path="product/:id"
                element={<ProductCard />}
             />

             <Route path="*" element={<NoMatch />}>
             </Route>
         </Routes>
    </div>
  );
}

export default App;
