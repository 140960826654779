import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toggleAgeExtra} from "../actions";
import {Offcanvas, Row} from "react-bootstrap";
import mainImage from '../images/1920x1080_vaaka_Nicotinell_mieskadulla.jpg';
import mainImageMobile from '../images/1920x1080_Nicotinell_1222.jpg';
import ReadMore from "../components/readMore";
import ReadMoreInner from "../components/readMoreInner";
import {useNavigate} from "react-router-dom";
import Header from "../components/header";

function Home (){
    const {showAgeExtra} = useSelector(state => state.homeReducer);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    let navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const timer = setTimeout(() => handleShow(), 3000);
        return () => clearTimeout(timer);
    }, []);

    const handleNoClick = () => {
         dispatch(toggleAgeExtra())
    };

    const handleYesClick = () => {
        navigate('/amount')
    };

    let ageDescriptionExtra = 'ageDescriptionExtra d-none fadeOut'

    if(showAgeExtra) {
        ageDescriptionExtra = 'ageDescriptionExtra small'
    }



    return (

        <div className='container'>
            <Header frontpage={true} />
            <div className='row'>
                <div className='col-12 no-padding'>
                    <img className='img-fluid d-none d-md-block' src={mainImage} alt='main'/>
                    <img className='img-fluid d-block d-md-none' src={mainImageMobile} alt='main'/>
                </div>
            </div>

            <ReadMore display={show}/>

            <Offcanvas show={show} placement={"bottom"} scroll={true} backdrop={false} onHide={handleClose}>
            <div className='container'>
                <div className='buttonGroup'>
                <div className="ageButtons" role="group" aria-label="Age buttons">
                    <button onClick={handleNoClick} type="button" className="btn btn-no">Ei</button>
                    <div className='ageBall'>
                        18
                    </div>
                    <button onClick={handleYesClick} type="button" className="btn btn-yes">Kyllä</button>
                </div>
            </div>
            <Row>
                <div className='ageDescription col-12'>
                    <h2>Olethan täysi-ikäinen?</h2>
                    <div className={ageDescriptionExtra}>
                        <p>Nikotiinikorvaushoitotuotteiden myynti alle 18-vuotiaille on kielletty. Mikäli olet alle 18-vuotias ja tarvitset tukea tupakoinnin lopettamiseen, käänny lääkärin puoleen.</p>
                    </div>
                    <ReadMoreInner />
                </div>
            </Row>
            </div>
            </Offcanvas>
        </div>

    )
}

export default Home;