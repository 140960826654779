import React from 'react';
import {Button} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import productNameCase from "../Utils";
import {setPathHistory} from "../actions";
import {useDispatch} from "react-redux";

function ProductSlide ({product}){
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    let productImageSource = '';

    const navigateToProduct = () => {
        let payload = {
            location
        }
        dispatch(setPathHistory(payload))
        navigate('/product/' + product.id)
    };

    if (product && product.brand === 'Nicotinell' && product.format === 'purukumi'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/Nicotinell_'+ product.subbrand.replaceAll(" ", "") +'_'+ product.strength.replaceAll(" ", "") +'_150x300.png'
    } else if(product && product.brand === 'Nicotinell' && product.format === 'tabletti'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/Nicotinell_mint_tabletti_36_'+ product.strength.replaceAll(" ", "") +'_150x300.png'
    } else if(product && product.brand === 'Nicotinell' && product.format === 'Laastari'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/Nicotinell_laastari_'+ product.strength.replaceAll(" ", "") +'_150x300.png'
    } else if(product && product.brand === 'Nicorette'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/nicorette.png'
    } else if(product && product.brand === 'Zonnic'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/zonnic.png'
    }
    else{
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/Nicotinell_icemint_4mg_150x300.png'
    }

    return (
        <div className='product-slide'>
            <div className='container no-padding'>
                <div className='row'>
                    <div className='col-6 pt-4'>
                    <h3>{product.brand}</h3>
                    <h2>{productNameCase(product.familyname)}</h2>
                    </div>
                    <div className='col-6'>
                    <img className='img-fluid product-image' src={productImageSource} alt="1" />
                    </div>

                </div>

            </div>

            <div className='product-slide-cta text-center'>
                <Button className='btn btn-secondary' onClick={navigateToProduct}>Tuotetiedot</Button>
            </div>
        </div>
    )
}

export default ProductSlide;