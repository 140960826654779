import React from "react";
import {
    useNavigate,
    useParams
} from "react-router-dom";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import ProductSlide from "../components/productSlide";
import ProductCarousel from "../components/productCarousel";
import {filteredProducts} from "../Utils";
import ReadMoreInner from "../components/readMoreInner";
import Header from "../components/header";

function MultipleProducts (){
    const {amountFilteredProductData} = useSelector(state => state.globalReducer);

    let { format, flavour } = useParams();
    let navigate = useNavigate();

    let initialProductData = amountFilteredProductData;
    let productSlides = [];

    document.title = 'Nicotinell valinta - ' + format +' '+ flavour


    const goToSelections = () => {
        navigate('/selection')
    };

    if (format && flavour){
        if (amountFilteredProductData){
            const arrRef = ["Nicotinell", "Nicorette", 'Zonnic'];
            initialProductData = filteredProducts(initialProductData, flavour, format)
            initialProductData.sort((a, b) => arrRef.indexOf(a.brand) - arrRef.indexOf(b.brand));
            productSlides = initialProductData.map(p => {
                return <ProductSlide key={p.id} product={p} />
            })
        }
    }

    let slides = productSlides


    return (
        <Container className='overflow-hidden'>
            <Header />
            <Row className='pb-5'>
                <Col className='text-center'>
                    <h1>Valitse tuote</h1>
                    <h3>{initialProductData && productSlides.length} kpl</h3>
                </Col>
            </Row>
            <ProductCarousel slides={slides} />
            <Row className='pt-3'>
                <Col className='text-center'>
                    <Button onClick={goToSelections} variant="secondary">Muuta valintoja</Button>
                </Col>
            </Row>
            <Row className='pb-3 justify-content-center'>
                <Col xs={12} md={10} className='text-center small color-white'>
                    <ReadMoreInner />
                </Col>
            </Row>
        </Container>
    )
}

export default MultipleProducts;