import React, {useState} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import productNameCase from '../Utils'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ProductCardBack from "../components/productCardBack";
import ReadMoreInner from "../components/readMoreInner";
import Header from "../components/header";

function ProductDetails ({product, pathHistory}){
    const {productData} = useSelector(state => state.globalReducer);
    let navigate = useNavigate();

    const [showBack, setShowBack] = useState(false);
    document.title = 'Nicotinell valinta - ' + product.new_familyname


    const toggleShowBack = () => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'product_usage_click',
                'product': product.new_familyname
            });
        }
        setShowBack(!showBack);
    }

    const handleBack = () => {
        navigate(pathHistory.pathname)
    };

    const goToSelections = () => {
        navigate('/selection')
    };


    let flipCardClassName = 'flip-card'
    let flipCardBackClassName = 'flip-card-back col-12 col-md-6'
    let flipCardFrontClassName = 'flip-card-front col-12 col-md-6'
    let packagingSizes = ''
    let showProperUse = false
    let productImageSource = '';
    let minMaxUsage = '';

    if (productData){
        packagingSizes = productData.filter(p => p.new_familyname === product.new_familyname).sort((a, b) => (parseInt(a.size) > parseInt(b.size)) ? 1 : -1).map(fp => (
            <span id={fp.id} key={fp.id}>{fp.size} kpl <br></br></span>
        ))
    }

    if (showBack){
        flipCardClassName += ' show-back'
    }

    if(product.brand === 'Nicotinell' && (product.format === 'purukumi' || product.format === 'tabletti')){
        showProperUse = true
    }else{
        flipCardBackClassName += ' d-none'
        flipCardFrontClassName += ' no-guide'
    }

    if (product && product.brand === 'Nicotinell' && product.format === 'purukumi'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/Nicotinell_'+ product.subbrand.replaceAll(" ", "") +'_'+ product.strength.replaceAll(" ", "") +'_150x300.png'
    } else if(product && product.brand === 'Nicotinell' && product.format === 'tabletti'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/Nicotinell_mint_tabletti_36_'+ product.strength.replaceAll(" ", "") +'_150x300.png'
    } else if(product && product.brand === 'Nicotinell' && product.format === 'Laastari'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/Nicotinell_laastari_'+ product.strength.replaceAll(" ", "") +'_150x300.png'
    } else if(product && product.brand === 'Nicorette'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/nicorette.png'
    } else if(product && product.brand === 'Zonnic'){
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/zonnic.png'
    }
    else{
        productImageSource = 'https://web.materiaali.fi/haleon/nicotinellvalinta/products/Nicotinell_icemint_4mg_150x300.png'
    }

    if(product.format === 'Laastari'){
        minMaxUsage = 'Suositeltava käyttömäärä '+ product.min_kayttosuositus +'kpl/vrk. '
    }else{
        minMaxUsage = 'Suositeltava käyttömäärä '+product.min_kayttosuositus+'–'+product.max_kayttosuositus+' kpl/vrk. '
    }

    return (
        <Container>
            <Header />
            <Row className='justify-content-center pt-md-3'>
                <Col xs={12} lg={10}>
                    <Card className={flipCardClassName}>
                      <div className='flip-card-inner row justify-content-center'>
                          <div className={flipCardFrontClassName}>
                              <div className='row pt-md-3'>
                                  <div className='col-12 text-center pb-2'>
                                      <p className='h3'>{product.brand}</p>
                                      <h2>{productNameCase(product.familyname)}</h2>
                                  </div>
                                  <div className='col-6'>
                                      <p><strong>Pakkauskoko</strong><br></br>
                                          {packagingSizes}
                                      </p>
                                  </div>
                                  <div className='col-6 text-right'>
                                      <img width='130px' className='img-fluid img-align-right product-card-image' src={productImageSource} alt="1" />
                                  </div>
                                  <div className='col-12 pt-3'>
                                    <p>{minMaxUsage}
                                         Max. {product.max_vrk} kpl/vrk. Hoitosuositusaika {product.hoitosuositusaika}
                                    </p>
                                      <p className='small'>
                                          Tutustu pakkausselosteeseen ennen käyttöä.
                                            Mikäli et löydä hyllystä sopivaa pakkauskokoa,
                                            ota yhteyttä henkilökuntaan.
                                      </p>
                                      {showProperUse &&
                                      <p className='text-center d-md-none'>
                                          <Button onClick={toggleShowBack}>Oikea käyttö</Button>
                                      </p>
                                      }
                                  </div>
                              </div>
                          </div>
                          <ProductCardBack
                              flipCardBackClassName={flipCardBackClassName}
                              toggleShowBack={toggleShowBack}
                              format={product.format}/>
                      </div>
                    </Card>
                </Col>
            </Row>
            {pathHistory &&
            <Row className='text-center pt-3'>
                <Col>
                    <Button onClick={handleBack}>
                        Takaisin listaukseen
                    </Button>
                </Col>
            </Row>
            }
            <Row className='pt-3'>
                <Col className='text-center'>
                    <Button onClick={goToSelections} variant="secondary">Muuta valintoja</Button>
                </Col>
            </Row>

            <Row className='pb-3 justify-content-center'>
                <Col xs={12} md={10} className='text-center small color-white'>
                    <ReadMoreInner />
                </Col>
            </Row>
        </Container>
    )
}

export default ProductDetails;