import React, {useState} from 'react';
import {Carousel} from '3d-react-carousal';
import {Row} from "react-bootstrap";
import ProductCarouselIndex from "./productCarouselIndex";

function ProductCarousel ({slides}){
    const [slideIndex, setSlideIndex] = useState(1);

     const callback = (index) =>{
        if(index+1 > slides.length){
            setSlideIndex(index - 1)
        }else{
            setSlideIndex(index + 1)
        }
     }

    return (
        <Row>
            <Carousel slides={slides} arrows={false} autoplay={false} onSlideChange={callback}/>
            <div className='slider-index'>
                <ProductCarouselIndex index={slideIndex} slidesLength={slides.length} />
            </div>
        </Row>

    )
}

export default ProductCarousel;