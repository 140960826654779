import React from 'react';
import homeIcon from '../images/home_icon_150x150.svg';
import haleonLogo from '../images/haleon_white.svg';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function Header ({frontpage}){
    const {store} = useSelector(state => state.globalReducer);
    let headerClassName = 'container header'
    if (frontpage){
        headerClassName += ' d-none d-md-block'
    }
    const navigate = useNavigate();

    const navigateToFront = () => {
        navigate('/?store=' + store)
    };

    return (
        <div className={headerClassName}>
            <Row>
                <Col xs={3} md={6}>
                   <div onClick={navigateToFront}><img className='homeIcon' src={homeIcon} alt='Home'/></div>
                </Col>
                <Col xs={6} md={6} className='text-center text-md-end'>
                    <img id='header-logo' className='img-fluid' src={haleonLogo} alt='Haleon'/>
                </Col>
            </Row>
        </div>
    )
}

export default Header;