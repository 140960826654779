/*function isDev(){
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return true
    } else {
        return false
    }
}*/
export default function productNameCase(str) {
  let name =  str.replace(/\w\S*/g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  name = name.replace(' Mg', '\u00A0mg');
  name = name.replace(' Tuntia', ' tuntia');
  return name
}

export function filteredProducts(initialProductData, selectedFlavour, selectedFormat){
    let getFilteredProducts = initialProductData.filter(p => { return p.format === selectedFormat && p.subsegment === selectedFlavour })
    return getFilteredProducts.filter((p, index) => index === getFilteredProducts.findIndex( sp => sp.familyname === p.familyname))
}
