import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import chewingInstructionsGum from "../images/pureskeluohjeet.svg";
import chewingInstructionsTablet from "../images/pureskeluohjeet_tabletti.svg";

function ProductCardBack ({flipCardBackClassName, toggleShowBack, format, brand}){

    let chewingInstructions = chewingInstructionsGum
    let formatString = 'Nicotinell-purukumi'
    let guideSteps = '<ol> <li>Pureskele hitaasti, kunnes maku tuntuu voimakkaalta.</li> <li>Pidä purukumia posken ja ikenen välissä, kunnes voimakas maku on hävinnyt</li> <li> Aloita pureskelu uudestaan. Jatka noin puolen tunnin ajan</li> </ol>'

    if (format === 'tabletti'){
        chewingInstructions = chewingInstructionsTablet
        formatString = 'Nicotinell-tabletti'
        guideSteps = '<ol><li>Imeskele hitaasti, kunnes maku tuntuu voimakkaalta.</li><li>Pidä tablettia posken ja ikenen välissä, kunnes voimakas maku on hävinnyt.</li><li>Aloita imeskely uudestaan. Jatka noin puolen tunnin ajan.</li></ol>\n'
    }

    return (
          <div className={flipCardBackClassName}>
              <Row className='pt-3'>
                  <Col xs={12} className='text-center pb-2'>
                      <p className='h3 d-lg-block d-none'><br></br></p>
                      <h2>Oikea käyttö</h2>
                  </Col>
                  <Col xs={12}>
                      <p>
                          <strong>{formatString}</strong>
                      </p>
                      <div dangerouslySetInnerHTML={{__html:guideSteps}}></div>
                  </Col>

                  <Col xs={12} className='pb-3'>
                        <img className='img-fluid' src={chewingInstructions} alt="Instructions" />
                  </Col>

                  <Col xs={12}>
                      <p>Lisätietoa pakkausselosteesta ja osoitteesta <a rel='noreferrer' target='_blank' href='https://www.nicotinell.fi'>www.nicotinell.fi</a></p>
                  </Col>

                  <Col xs={12} className='pt-3 d-md-none'>
                      <p className='text-center'>
                          <Button onClick={toggleShowBack}>Tuotetiedot</Button>
                      </p>
                  </Col>
              </Row>
          </div>
    )
}

export default ProductCardBack;