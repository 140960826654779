import {
    SET_PATH_HISTORY,
    SET_PRODUCT_DATA,
    SET_SELECTED_AMOUNT, SET_SELECTED_FLAVOUR, SET_SELECTED_FORM, SET_STORE, TOGGLE_AMOUNT_OPTIONS,
} from "../actions/types";

const initialState = {
    selectedAmount: 1,
    amountOptionsVisible: false,
    productData: null,
    amountFilteredProductData: null,
    selectedForm: null,
    selectedFlavour: null,
    pathHistory: null,
    store: null
};

function globalReducer(state = initialState, action) {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case SET_SELECTED_AMOUNT:
            return Object.assign({}, state, {
                selectedAmount: action.payload.selectedAmount,
                amountFilteredProductData: action.payload.amountFilteredProductData
            });
        case SET_STORE:
            return Object.assign({}, state, {
                store: action.payload.store
            });
        case TOGGLE_AMOUNT_OPTIONS:
            return Object.assign({}, state, {
                amountOptionsVisible: !state.amountOptionsVisible
            });
        case SET_PRODUCT_DATA:
            return Object.assign({}, state, {
                productData: action.payload.productData,
                amountFilteredProductData: action.payload.amountFilteredProductData,
                store: action.payload.store
            });
        case SET_SELECTED_FORM:
            return Object.assign({}, state, {
                selectedForm: action.payload.selectedForm
            });
        case SET_SELECTED_FLAVOUR:
            return Object.assign({}, state, {
                selectedFlavour: action.payload.selectedFlavour
            });
        case SET_PATH_HISTORY:
            return Object.assign({}, state, {
                pathHistory: action.payload.location
            });
        default:
            return state;
    }
}

export default globalReducer;