import React from 'react';
import {Col, Row} from "react-bootstrap";
import ReadMoreInner from "./readMoreInner";

function ReadMore ({display}){

    let rowClassName = 'pt-5 pb-3 justify-content-center d-md-flex d-none'
    if(display){
        rowClassName += ' fadeOut'
    }

    return (
        <Row className={rowClassName}>
            <Col xs={12} md={10} className='text-center small color-white'>
                <ReadMoreInner />
            </Col>
        </Row>
    )
}

export default ReadMore;