import React from 'react';

function ReadMoreInner (){

    return (
        <div className='small'>
            <a className='btn btn-primary mb-3 mt-3' href='https://nicotinell.fi/' rel="noreferrer" target='_blank'>Lue lisää: <span>Nicotinell.fi</span></a>
                <p>Nicotinell avuksi tupakoinnin lopettamisen ja vähentämisen yhteydessä esiintyviin vieroitusoireisiin. Sisältää nikotiinia.
                Lue pakkausseloste. Ei alle 18-vuotiaille. GSK Consumer Healthcare. Tavaramerkit ovat GSK-konsernin omistamia tai sille lisensoituja.
                ©2022 GSK tai lisenssinhaltija.<br></br> PM-FI-NICOT-23-00001 ja PM-FI-NICOT-22-00035</p>
        </div>
    )
}

export default ReadMoreInner;