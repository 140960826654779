import {
    SET_SELECTED_AMOUNT,
    SHOW_AGE_EXTRA,
    SHOW_AGE_TOGGLE,
    TOGGLE_AMOUNT_OPTIONS,
    SET_PRODUCT_DATA,
    SET_SELECTED_FORM,
    SET_SELECTED_FLAVOUR,
    SET_PATH_HISTORY,
    SET_STORE
} from "./types";

export function importProductData(payload) {
    return {type: SET_PRODUCT_DATA, payload};
}

export function setStore(payload) {
    return {type: SET_STORE, payload};
}

export function showAgeToggle() {
    return {type: SHOW_AGE_TOGGLE};
}

export function toggleAmountOptions() {
    return {type: TOGGLE_AMOUNT_OPTIONS};
}

export function toggleAgeExtra() {
    return {type: SHOW_AGE_EXTRA};
}

export function setSelectedAmount(payload) {
    return {type: SET_SELECTED_AMOUNT, payload};
}

export function setSelectedForm(payload) {
    return {type: SET_SELECTED_FORM, payload};
}

export function setSelectedFlavour(payload) {
    return {type: SET_SELECTED_FLAVOUR, payload};
}

export function setPathHistory(payload) {
    return {type: SET_PATH_HISTORY, payload};
}