import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Offcanvas, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {filteredProducts} from "../Utils";
import ReadMoreInner from "../components/readMoreInner";
import {setPathHistory} from "../actions";
import Header from "../components/header";

function Selection (){

    const {amountFilteredProductData} = useSelector(state => state.globalReducer);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selectedFormat, setSelectedFormat] = useState(null);
    const [selectedFlavour, setSelectedFlavour] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const timer = setTimeout(() => handleShow(), 200);
        return () => clearTimeout(timer);
    }, []);

    let initialProductData = amountFilteredProductData;

    const handleFormatSelect = (value, disabled) => {
        if(!disabled){
            if(value === 'Laastari'){
                let filteredBandageProducts = filteredProducts(initialProductData, 'nikotiini', value)
                if (filteredBandageProducts.length > 1){
                    navigate('/selection/' + value + '/nikotiini/')
                }else{
                    navigate('/product/' + filteredBandageProducts[0].id)
                }
            }


            setSelectedFormat(value)
            if(selectedFlavour && initialProductData){
                let getFilteredProducts = filteredProducts(initialProductData, selectedFlavour, value)
                if(getFilteredProducts.length > 1){
                    navigate('/selection/' + value + '/' + selectedFlavour + '/')
                }else{
                    let payload = {
                        'location': null,
                    }
                    dispatch(setPathHistory(payload))
                    navigate('/product/' + getFilteredProducts[0].id)
                }
            }
        }

    };

    const handleFlavourSelect = (value, disabled) => {
        if(!disabled){
            setSelectedFlavour(value)
            if(selectedFormat && initialProductData){
                let getFilteredProducts = filteredProducts(initialProductData, value, selectedFormat)
                if(getFilteredProducts.length > 1){
                    navigate('/selection/' + selectedFormat + '/' + value + '/')
                }else{
                    let payload = {
                        'location': null,
                    }
                    dispatch(setPathHistory(payload))
                    navigate('/product/' + getFilteredProducts[0].id)
                }
            }
        }
    };

    let formChoices = '';
    let flavourChoices = '';
    let availableProductFormats = [];
    let availableProductFlavours = [];

    if (initialProductData){
        let filteredProductData = initialProductData

        if(selectedFlavour){
            filteredProductData = filteredProductData.filter(p => {
                if(p.subsegment === selectedFlavour){
                    return p
                }else{
                    return ''
                }
            })
            availableProductFormats = filteredProductData.map(p => p.format)
        }

        let formSelections = ['purukumi', 'tabletti', 'Laastari']

        formChoices = formSelections.map(form => {
            let formClassName = 'choice';
            let disabled = false;
            if (form === selectedFormat){
                formClassName += ' active-choice'
            }
            if (selectedFlavour && availableProductFormats.indexOf(form) < 0){
                formClassName += ' disabled'
                disabled = true
            }

            if(form === 'purukumi'){
                return <div key={form} onClick={() => handleFormatSelect(form, disabled)} className={formClassName}>
                    <h3>Purukumi</h3>
                    <p>aktiivista tekemistä</p>
                </div>
            }else if(form === 'tabletti'){
                return <div key={form} onClick={() => handleFormatSelect(form, disabled)} className={formClassName}>
                    <h3>Tabletti</h3>
                    <p>huomaamaton apu</p>
                </div>
            }else if(form === 'Laastari'){
                return <div key={form} onClick={() => handleFormatSelect(form, disabled)} className={formClassName}>
                    <h3>Laastari</h3>
                    <p>hillitsee tupakanhimoa 24 h</p>
                </div>
            }
            else{
                return ''
            }

        })

        if (selectedFormat){
            filteredProductData = filteredProductData.filter(p => {
                if(p.format === selectedFormat){
                    return p
                }else{
                    return ''
                }
            })
            availableProductFlavours = filteredProductData.map(p => p.subsegment)
        }

        let flavourSelections = ['mint', 'lakritsi', 'fruit', 'nikotiini']
        flavourChoices = flavourSelections.map(flavour => {
            let flavourClassName = 'choice flavour';
            let disabled = false;
            if (flavour === selectedFlavour){
                flavourClassName += ' active-choice'
            }
            if (selectedFormat && availableProductFlavours.indexOf(flavour) < 0){
                flavourClassName += ' disabled'
                disabled = true;
            }

            return <div onClick={() => handleFlavourSelect(flavour, disabled)} key={flavour} className={flavourClassName}>
                <h3>{flavour}</h3>
            </div>


        })

    }

    let selectionContainerClassName = 'selectionContainer'
    let selectionContainerLowerClassName = 'selectionContainer lower'

    return (
        <Container fluid>
            <Header />
            <Row className='pb-5 pt-5'>
                <Col className='text-center'>
                    <h1>Valitse seuraavaksi sinulle sopiva tuotemuoto ja maku</h1>
                </Col>
            </Row>
          <Offcanvas show={show} placement={"bottom"} scroll={true} backdrop={false} onHide={handleClose}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6 no-padding'>
                        <div className={selectionContainerClassName}>
                            <div className='choice-container'>
                                <Button variant='secondary'>Tuotemuodot</Button>
                                {formChoices}
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className={selectionContainerLowerClassName}>
                            <div className='choice-container'>
                                <Button  className='btn-dark-blue'>Maut</Button>
                                {flavourChoices}
                            </div>
                        </div>
                    </div>
                    <div className='row pt-5 pb-3 justify-content-center d-md-flex d-none'>
                        <div className='col-12 col-md-10 text-center '>
                            <ReadMoreInner />
                        </div>
                    </div>
                </div>
            </div>
          </Offcanvas>
        </Container>
    )
}

export default Selection;