import React from 'react';

function ProductCarouselIndex ({index, slidesLength}){
    return (
        <div className='slider-index'>
            <h3>{index} / {slidesLength}</h3>
        </div>
    )
}

export default ProductCarouselIndex;