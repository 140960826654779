import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Offcanvas, Row} from "react-bootstrap";
import {setSelectedAmount} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ReadMoreInner from "../components/readMoreInner";
import Header from "../components/header";

function Base (){
    const {productData} = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const timer = setTimeout(() => handleShow(), 500);
        return () => clearTimeout(timer);
    }, [dispatch]);


    const handleAmountSelected = (value) => {
        let amountFilteredProductData = productData
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            if(value === 1){
                window.dataLayer.push({
                    'event': 'amount_selection',
                    'amount': 'alle 20 päivässä'
                });
            }else{
                window.dataLayer.push({
                    'event': 'amount_selection',
                    'amount': 'yli 20 päivässä'
                });
            }
        }
        if (productData){
            amountFilteredProductData = productData.filter(p => {
                if(value === 1){
                    return p.amount_1 !== '';
                }else {
                    return p.amount_3 !== '';
                }
            });
        }

        const payload = {
            selectedAmount: value,
            amountFilteredProductData: amountFilteredProductData
        };

        dispatch(setSelectedAmount(payload));
        navigate("/selection");
    };


    return (
        <Container>
            <Header />
            <Row className='pt-5 pb-4 justify-content-center'>
                <Col xs={12} md={8} className='text-center'>
                    <p><strong>Tupakoinnin lopettaminen
                        on vaikeaa.<br></br></strong>
                        Siksi oikean tuotteen ja
                        vahvuuden löytäminen on
                        tärkeää. Aloita valitsemalla
                        montako savuketta keskimäärin
                        poltat päivässä.</p>
                </Col>
            </Row>
            <Row className='pb-5'>
                <Col className='text-center'>
                    <h1>Montako savuketta poltat päivässä?</h1>
                </Col>
            </Row>

          <Offcanvas show={show} placement={"bottom"} scroll={true} backdrop={false} onHide={handleClose}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6 no-padding'>
                    <div className='choice-container'>
                    <Button className='btnShadow' variant='secondary' onClick={() => handleAmountSelected(1)} >Alle 20 päivässä</Button>
                    </div>
                    </div>
                    <div className='col-12 col-md-6'>
                    <div className='choice-container'>
                    <Button onClick={() => handleAmountSelected(2)}>yli 20 päivässä</Button>
                    </div>
                    </div>
                </div>
                <div className='row pt-3 pb-3 justify-content-center d-md-flex d-none'>
                    <div className='col-12 col-md-10 text-center '>
                        <ReadMoreInner />
                    </div>
                </div>
            </div>
          </Offcanvas>
        </Container>
    )
}

export default Base;